var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*transform from RA filter to mongodb filter so nestjs CRUD can handle
from RA frontend can add filter with source = source_gte, source_lte. source_neq
from filter:
{ date_gte: '2022-09-09', date_lte: '2022-09-30',abc1_neq: '2022-09-30', abc: 1 }
to filter:
{
  date: { '$gte': '2022-09-09', '$lte': '2022-09-30' },
  abc1: { '$neq': '2022-09-30' },
  abc: 1
}
*/
var filterTransform = function (filter) {
    var _a, _b;
    var newFilter = {};
    for (var _i = 0, _c = Object.entries(filter); _i < _c.length; _i++) {
        var _d = _c[_i], key = _d[0], value = _d[1];
        var includeOptions = key.includes("_gte") ||
            key.includes("_gt") ||
            key.includes("_lte") ||
            key.includes("_lt") ||
            key.includes("_ne");
        if (key.includes("_") && includeOptions) {
            var _e = key.split("_"), field = _e[0], operator = _e[1];
            newFilter.hasOwnProperty(field)
                ? (newFilter[field] = __assign(__assign({}, newFilter[field]), (_a = {}, _a["$" + operator] = value, _a)))
                : (newFilter[field] = (_b = {}, _b["$" + operator] = value, _b));
        }
        else {
            newFilter[key] = value;
        }
    }
    return newFilter;
};
var localStorageManager = {
    removeItems: function () {
        localStorage.removeItem("username");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("fullName");
        localStorage.removeItem("refreshToken");
    },
    removeItem: function (key) {
        localStorage.removeItem(key);
    },
    setItems: function (username, accessToken, refreshToken, fullName) {
        localStorage.setItem("username", username);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("fullName", fullName);
    },
    setItem: function (key, value) {
        localStorage.setItem(key, value);
    },
    getItems: function () { return ({
        username: localStorage.getItem("username"),
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken"),
        fullName: localStorage.getItem("fullName"),
    }); },
    getItem: function (key) { return localStorage.getItem(key); },
};
export { filterTransform, localStorageManager };
